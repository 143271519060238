<template>
    <div class="wrap" :class="{ wrap_en: $i18n.locale == 'EN' }">
        <my-header></my-header>
        <div class="frist_page">
            <div class="banner_text">
                <p>{{ $t('Public.Home.banner.title1') }}</p>
                <p>{{ $t('Public.Home.banner.title2') }}</p>
                <div style="margin-top: 0.2rem">{{ $t('Public.Home.banner.title3') }}</div>
                <!-- <div>{{$t('Public.Home.banner.title4')}}</div> -->
                <!-- <div>{{$t('Public.Home.banner.content')}}</div> -->
                <p class="page_slogan">{{ $t('Public.Home.banner.slogan') }}</p>
            </div>
        </div>
        <div class="second_page">
            <p class="page_title">{{ $t('Public.Home.aboutUs.title') }}</p>
            <div class="second_text">{{ $t('Public.Home.aboutUs.content1') }}</div>
            <div class="second_text">{{ $t('Public.Home.aboutUs.content2') }}</div>
            <div class="second_text">{{ $t('Public.Home.aboutUs.content3') }}</div>
            <div class="sigreal_img">
                <img src="../../public/images/homepage/sigreal.png" />
            </div>
        </div>
        <div class="third_page" v-if="$i18n.locale == 'EN'">
            <p class="page_title">{{ $t('Public.Home.ourCulture.title') }}</p>
            <div class="culture_wall">
                <div class="line1"></div>
                <div class="line2"></div>
                <div class="line3"></div>
                <ul>
                    <li>
                        <div><img src="../../public/images/homepage/icon_chengjiu.png" /></div>
                        <p>{{ $t('Public.Home.ourCulture.cultureTitle[0]') }}</p>
                    </li>
                    <li>
                        <div><img src="../../public/images/homepage/icon_wushi.png" /></div>
                        <p>{{ $t('Public.Home.ourCulture.cultureTitle[1]') }}</p>
                    </li>
                    <li>
                        <div><img src="../../public/images/homepage/icon_zhengzhi.png" /></div>
                        <p>{{ $t('Public.Home.ourCulture.cultureTitle[2]') }}</p>
                    </li>
                    <li>
                        <div><img src="../../public/images/homepage/icon_manzugan.png" /></div>
                        <p>{{ $t('Public.Home.ourCulture.cultureTitle[3]') }}</p>
                    </li>
                    <li>
                        <div><img src="../../public/images/homepage/icon_zhixingli.png" /></div>
                        <p>{{ $t('Public.Home.ourCulture.cultureTitle[4]') }}</p>
                    </li>
                    <li>
                        <div><img src="../../public/images/homepage/icon_leguanjinqu.png" /></div>
                        <p>{{ $t('Public.Home.ourCulture.cultureTitle[5]') }}</p>
                    </li>
                </ul>
            </div>
        </div>
        <div class="third1_page">
            <p class="page_title" style="color: #fff">{{ $t('Public.Home.service.title') }}</p>
            <div class="card_box">
                <div class="card" v-for="(cardItem, cardIndex) in cardArr" :key="cardIndex" @click="handleRoute(cardIndex)">
                    <h3>{{ $t('Public.Home.service.list[' + cardIndex + '].title') }}<img src="../../public/images/service/jiantou.png" /></h3>
                    <div class="card_txt">{{ $t('Public.Home.service.list[' + cardIndex + '].txt1') }}</div>
                </div>
            </div>
        </div>
        <div class="fourth_page">
            <p class="page_title">{{ $t('Public.Home.quote.title') }}</p>
            <p class="page_sign">{{ $t('Public.Home.quote.sign1') }}</p>
            <p class="page_sign">{{ $t('Public.Home.quote.sign2') }}</p>
        </div>
        <my-footer></my-footer>
    </div>
</template>

<script>
// @ is an alias to /src
import MyHeader from '@/components/MyHeader.vue';
import MyFooter from '@/components/MyFooter.vue';
export default {
    name: 'Home',
    components: {
        MyHeader,
        MyFooter
    },
    data() {
        return {
            slideNum: 0, //滑动次数
            slideMax: 99, //最大可滑动次数
            cardArr: [{}, {}, {}]
        };
    },
    methods: {
        handleRoute(index) {
            let urlObj = {
                0: '/Service/UBIService',
                1: '/Service/ControlService',
                2: '/Service/SecurityService'
            };

            this.$router.push(urlObj[index]);
        }
    },
    mounted() {
        var _this = this;
    }
};
</script>
<style scoped>
.frist_page {
    width: 100%;
    position: relative;
    height: 7.03rem;
    background: url(../../public/images/homepage/banner1.jpg) no-repeat;
    background-size: 100% 100%;
    padding: 2.2rem 0 0 0.5rem;
    background-color: #0c1f3d;
}
.wrap_en .frist_page {
    padding-top: 2.7rem;
}
.banner_text {
    width: 100%;
    font-size: 0.46rem;
    line-height: 1.5;
    color: #fff;
}
.wrap_en .banner_text {
    /* padding-top:0.6rem; */
}
.banner_text div {
    font-size: 0.24rem;
    line-height: 1.5;
    /* margin-top: 0.3rem; */
    /* text-align: justify; */
    /* width: 3.08rem; */
}
.second_page {
    padding: 0.5rem 0.2rem 1.3rem;
    color: #fff;
    background-color: #010d16;
}
.banner_text .page_slogan {
    text-align: center;
    font-size: 0.2rem;
    font-style: italic;
    padding-bottom: 0.3rem;
    position: absolute;
    bottom: 0.1rem;
    color: #028888;
    text-shadow: 0px 0px 12px #028888;
}
.sigreal_img {
    margin-top: 0.7rem;
}
.page_title {
    font-size: 0.42rem;
    font-weight: 500;
    text-align: center;
    margin-bottom: 0.35rem;
}
.second_text {
    text-indent: 2em;
    line-height: 1.71;
    text-align: justify;
    text-justify: inter-ideograph;
}
.wrap_en .second_text {
    text-align: left;
    text-align: justify;
}
.third_page {
    width: 100%;
    height: 8.58rem;
    background: url(../../public/images/homepage/wenhuabg.jpg) no-repeat;
    background-size: 100% 100%;
    padding-top: 0.55rem;
    margin: 0 auto;
}
.third_page .page_title {
    color: #fff;
}
.culture_wall {
    position: relative;
    width: 100%;
    /* height: 4rem; */
    margin: 0.5rem auto 0;
    color: #fff;
}
.line1 {
    width: 1px;
    height: 5.63rem;
    background-color: #fff;
    position: absolute;
    top: 0;
    left: 3.54rem;
    z-index: 10;
}
.line2,
.line3 {
    width: 4.74rem;
    height: 1px;
    background-color: #fff;
    position: absolute;
    top: 1.72rem;
    left: 1.18rem;
    z-index: 10;
}
.line3 {
    top: 3.97rem;
}
.wrap_en .line1,
.wrap_en .line2,
.wrap_en .line3 {
    display: none;
}
.culture_wall ul {
    width: 6.7rem;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.culture_wall ul li {
    width: 3.34rem;
    text-align: center;
    margin-bottom: 0.8rem;
}
.wrap_en .culture_wall ul {
    display: block;
    padding-left: 1rem;
}
.wrap_en .culture_wall ul li {
    margin-bottom: 0.3rem;
    display: flex;
    align-items: center;
    width: 100%;
}
.wrap_en .culture_wall ul li div {
    margin: 0 0.3rem 0 0;
}
.wrap_en .culture_wall ul li p {
    margin: 0;
}
.culture_wall ul li div {
    width: 0.7rem;
    margin: 0 auto;
}
.culture_wall ul li p {
    font-size: 0.34rem;
    margin-top: 0.15rem;
}
.fourth_page {
    padding: 0.56rem 0 0.72rem;
    color: #fff;
    width: 100%;
    background: url(../../public/images/homepage/milestones_bg.png) no-repeat;
    background-size: 100% 100%;
    /* background-color: #004444; */
    /* height: 13.34rem;
		background: url(../../public/images/homepage/milestones_bg.jpg) no-repeat;
		background-size: 100% 100%; */
}
.third1_page {
    background: #0f1b27;
    /* background: #fff; */
    padding: 0.35rem 0.3rem;
    margin-top: -1px;
}
.third1_page .card_box .card {
    width: 100%;
    height: 100%;
    padding: 4.8vw 6.4vw;
    border-radius: 4.27vw;
    /* background: #f6f6fb; */
    background: #d4d4d5;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    margin-bottom: 0.5rem;
}
.card h3 {
    position: relative;
}
.card h3 img {
    width: 0.3rem;
    position: absolute;
    right: 0.1rem;
    top: 0;
}
.card .card_txt {
    width: 90%;
    margin-top: 0.2rem;
}
.fourth_page {
    padding-right: 0.2rem;
    padding-left: 0.2rem;
}

.fourth_page .page_sign {
    color: #a7a7a7;
    padding-left: 0.3rem;
    font-size: 0.25rem;
}

.steps {
    position: relative;
    width: 7.1rem;
    margin: 0.4rem auto 0;
    /* height: 11.2rem; 
		overflow-y: auto; */
}
.steps ul {
    font-size: 0.3rem;
}
.steps li {
    padding: 0.4rem 0;
    border-bottom: 1px dashed #d8d8d8;
    display: flex;
    justify-content: space-between;
}
.steps_text {
    width: 4rem;
}
.steps_date {
    margin-top: 0.3rem;
    font-size: 0.24rem;
    line-height: 1.375;
}
.steps_dics {
    text-align: justify;
    line-height: 1.5;
    font-weight: 400;
}
.wrap_en .steps_dics {
    text-align: left;
}
.steps_img {
    width: 2.84rem;
}
</style>
