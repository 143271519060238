// 这个方法通过递归找到滚动的元素,用于置顶页面
function getScrollParent (node) {
  if (node == null) {
    return null
  }
  if (node.scrollHeight > node.clientHeight) {
    return node
  } else {
    return getScrollParent(node.parentNode)
  }
}

export {getScrollParent}