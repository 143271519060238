const info={
    CN: {  //中文翻译
		modal:{
			title:'职位申请表',
			text:'请如实填写您的信息，我们将尽快与您联系',
			placeholder:{
				name:'请填写您的姓名',
				phone:'请填写您的手机号'
			},
			fileName:'上传简历附件',
			button:'确认提交',
			nameHint:'抱歉，请填写您的姓名',
			fileHint:'抱歉，请上传您的简历',
			phoneHint1:'抱歉，请填写您的手机号',
			phoneHint2:'抱歉，请填写正确的手机号',
			resumeHint:'抱歉，请上传简历',
			confirmBtn:'确 定',
			successTip:'我们将尽快与您联系',
			failedTip1:'抱歉，系统出错',
			failedTip2:'请重新申请该职位'
		}
    },
    EN: {  //英文翻译
		modal:{
			title:'',
			text:"We're looking forward to contacting you!",
			placeholder:{
				name:'name*',
				phone:'phone*'
			},
			fileName:'My Resum',
			button:'Apply Now',
			nameHint:'Name field is required.',
			fileHint:'Resum field is required.',
			phoneHint1:'Phone field is required.',
			phoneHint2:'Please enter the right number.',
			resumeHint:'Resum field is required.',
			confirmBtn :'Ok',
			successTip:"We'll contact you as soon as possible.",
			failedTip1:'System Error',
			failedTip2:'Please reapply for this position!'
		}
    }
}
export default info  //需要暴露出去  