import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)
var baseTitle='宏瓴科技'
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
	meta: {
		title: baseTitle,
		keepAlive:false
	},
  },
  {
    path: '/CompanyDynamic',
    name: 'CompanyDynamic',
    component: () => import('../views/CompanyDynamic.vue'),
	meta: {
		title: baseTitle,
		keepAlive:false
	},
  },
  {
    path: '/Service/UBIService',
    name: 'UBIService',
    component: () => import('../views/UBIService.vue'),
	meta: {
		title: baseTitle,
		keepAlive:false
	},
  },
  {
    path: '/Service/NEVService',
    name: 'NEVService',
    component: () => import('../views/NEVService.vue'),
	meta: {
		title: baseTitle,
		keepAlive:false
	},
  },
  {
    path: '/Service/SecurityService',
    name: 'SecurityService',
    component: () => import('../views/SecurityService.vue'),
	meta: {
		title: baseTitle,
		keepAlive:false
	},
  },
  {
    path: '/Service/ControlService',
    name: 'ControlService',
    component: () => import('../views/ControlService.vue'),
	meta: {
		title: baseTitle,
		keepAlive:false
	},
  },
  {
    path: '/JoinUs',
    name: 'JoinUs',
    component: () => import('../views/JoinUs.vue'),
	meta: {
		title: baseTitle,
		keepAlive:false
	},
  },
  {
    path: '/PostList',
    name: 'PostList',
    component: () => import('../views/PostList.vue'),
	meta: {
		title: baseTitle,
		keepAlive:false
	},
  },
  {
    path: '/PostDetail',
    name: 'PostDetail',
    component: () => import('../views/PostDetail.vue'),
	meta: {
		title: baseTitle,
		keepAlive:false
	},
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
