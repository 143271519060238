<template>
  <div id="app" >
   <!-- <router-link to="/"></router-link>
    <router-view/> -->
	<keep-alive>
	  <router-view v-if="$route.meta.keepAlive"></router-view>
	</keep-alive>
	<router-view v-if="!$route.meta.keepAlive" />
  </div>
</template>
<script type="text/javascript">
	(function(doc, win) {
		//用原生方法获取用户设置的浏览器的字体大小(兼容ie)
		if(doc.documentElement.currentStyle) {
			var user_webset_font=doc.documentElement.currentStyle['fontSize'];
		}else {
			var user_webset_font=getComputedStyle(doc.documentElement,false)['fontSize'];
		}
		//取整后与默认16px的比例系数
		var xs=parseFloat(user_webset_font)/16;
		//设置rem的js设置的字体大小
		var view_jsset_font,result_font;
		var docEl = doc.documentElement,
		resizeEvt = 'orientationchange' in window ? 'orientationchange' : 'resize',
		clientWidth,
		recalc = function() {
			clientWidth = docEl.clientWidth;
			if(!clientWidth) return;
			if(!doc.addEventListener) return;
			if(clientWidth<750){
			//设置rem的js设置的字体大小
				view_jsset_font=100 * (clientWidth / 750);
			//最终的字体大小为rem字体/系数
				result_font=view_jsset_font/xs;
			//设置根字体大小
				docEl.style.fontSize = result_font + 'px';
			}else{
				docEl.style.fontSize = 100 + 'px';
			}
		};
		win.addEventListener(resizeEvt, recalc, false);
		doc.addEventListener('DOMContentLoaded', recalc, false);
	})(document, window);
</script>
<style>
	html,body,#app{
		height: 100%;
	}
	#app {
		font-family: "Helvetica Neue", Helvetica,Arial,"PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", 微软雅黑,  sans-serif;
	  -webkit-font-smoothing: antialiased;
	  -moz-osx-font-smoothing: grayscale;
	  color: #333;
	  font-size: 0.28rem;
	  overflow-x: hidden;
	  -webkit-overflow-scrolling: touch;
	  -webkit-text-size-adjust: 100% ;
	}
	/*响应式通用样式*/
	*{margin: 0;padding: 0;-webkit-box-sizing: border-box;-moz-box-sizing: border-box; box-sizing: border-box;}
	/*html{font-size:20px;}*/
	ul,li,p,h1,h2,h3,h4,h5,h6,dl,dt,dd,input { border:none; list-style:none;}
	a{text-decoration: none;color:#333;}
	b{font-weight: normal;}
	input,select {-webkit-appearance:none;-webkit-border-radius: 0;}
	img{width:100%;display: block;}
	.flex{display: flex;align-items: center;justify-content: space-between;}
	[v-cloak] {
	    display:none;
	}
	.wrap{
	    position: relative;
		background-color: #fff;
	    /* min-width: 1200px; */
	}
</style>
