<template>
    <div class="header" :class="{ white_bg: whiteBg }" @click="whiteBg = true" @touchmove="windowScroll">
        <div class="section">
            <div class="logo">
                <img v-show="!whiteBg" src="../../public/images/logo.png" />
                <img v-show="whiteBg" src="../../public/images/logo2.png" />
            </div>
            <div class="crumbs" @click="menusShow = true">
                <img v-show="!whiteBg" src="../../public/images/crumbs_1.png" />
                <img v-show="whiteBg" src="../../public/images/crumbs_2.png" />
            </div>
        </div>
        <van-popup v-model="menusShow" position="right" :style="{ height: '100%' }">
            <div class="menus_box">
                <el-menu :default-active="$route.path" class="el-menu-demo" active-text-color="#05A3E4" router menu-trigger="click">
                    <el-menu-item index="/">{{ $t('Public.Header.menus[0]') }}</el-menu-item>
                    <!-- <el-menu-item index="/Service">服务</el-menu-item> -->
                    <!-- <el-submenu index="Service">
                        <template slot="title">{{ $t('Public.Header.menus[1]') }}</template>
                        <el-menu-item index="/Service/UBIService">{{ $t('Public.Header.menus2[0]') }}</el-menu-item>
                        <el-menu-item index="/Service/ControlService">{{ $t('Public.Header.menus2[2]') }}</el-menu-item>
                        <el-menu-item index="/Service/SecurityService">{{ $t('Public.Header.menus2[3]') }}</el-menu-item>
                    </el-submenu> -->
                    <el-menu-item index="/CompanyDynamic">{{ $t('Public.Header.menus[2]') }}</el-menu-item>
                    <el-menu-item index="/JoinUs">{{ $t('Public.Header.menus[3]') }}</el-menu-item>
                </el-menu>
                <div class="change_language"><span @click="changeLanguage('CN')" :class="{ active: $i18n.locale == 'CN' }">中文</span> / <span @click="changeLanguage('EN')" :class="{ active: $i18n.locale == 'EN' }">EN</span></div>
            </div>
        </van-popup>
        <div v-show="backTopVisible" class="back_top" @click="backTop"><img src="../../public/images/back_top.png" /></div>
    </div>
</template>

<script>
import { getScrollParent } from '@/assets/js/common.js';
export default {
    name: 'MyHeader',
    props: {
        msg: String
    },
    data() {
        return {
            activeIndex: '/',
            backTopVisible: false,
            menusShow: false,
            whiteBg: false
        };
    },
    methods: {
        changeLanguage(sign) {
            if (this.$i18n.locale != sign) {
                this.$i18n.locale = sign;
                // this.$i18n.locale=='CN'?this.$i18n.locale='EN':this.$i18n.locale='CN'   //设置中英文模式
                localStorage.setItem('languageSet', this.$i18n.locale); //将用户设置存储到localStorage以便用户下次打开时使用此设置
            }
        },
        backTop() {
            const element = getScrollParent(this.$el);
            var timer = setInterval(() => {
                if (element.scrollTop > 0) {
                    element.scrollTop -= 80;
                } else {
                    clearTimeout(timer);
                }
            }, 50);
        },
        windowScroll() {
            const element = getScrollParent(this.$el);
            // console.log(element.scrollTop);
            if (element) {
                var scrollTop = element.scrollTop;
                if (scrollTop > 10) {
                    this.whiteBg = true;
                } else {
                    this.whiteBg = false;
                }
                if (scrollTop > 200) {
                    this.backTopVisible = true;
                } else {
                    this.backTopVisible = false;
                }
            }
        }
    },
    mounted() {
        setTimeout(() => {
            const element = getScrollParent(this.$el);
            element.scrollTop = 0;
        }, 1500);
        window.addEventListener('scroll', this.windowScroll, true);
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.windowScroll);
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.back_top {
    width: 1.2rem;
    position: fixed;
    bottom: 1.5rem;
    right: 0.1rem;
    z-index: 9999;
}
.header {
    width: 100%;
    height: 0.88rem;
    background: rgba(255, 255, 255, 0.32);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    /* transition:all 0.6s; */
}
.white_bg {
    background: #fff;
}
.section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    padding: 0 0.3rem;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5000;
}
.white_bg .section {
    background-color: #fff;
    border-bottom: 1px solid #d8d8d8;
}
.logo {
    width: 1.73rem;
    /* margin-right:2.8rem; */
}
.crumbs {
    width: 0.44rem;
}
.menus_box {
    width: 4rem;
    height: 100%;
    background: #f4f3f3;
    padding-top: 0.88rem;
}
.menus_box .el-menu-demo {
    background: #f4f3f3;
}
.el-menu-demo .el-menu-item,
/deep/.el-submenu__title {
    font-size: 0.28rem;
    height: 0.8rem;
    line-height: 0.8rem;
}

.change_language {
    padding-left: 20px;
    cursor: pointer;
    height: 0.8rem;
    line-height: 0.8rem;
}
.change_language .active {
    color: #05a3e4;
}
.white_bg .change_language {
    color: #333;
}
/deep/.van-overlay {
    top: 0.88rem;
}
/deep/.van-popup--right {
    /* top:0.88rem; */
}
.is-active {
    background-color: #fff;
}
.el-menu-item:focus,
.el-menu-item:hover {
    background-color: #fff;
}
.el-submenu .el-menu-item {
    padding-right: 30px;
    overflow-wrap: break-word;
    /*就是下面这一句，好像网上没有这一个的说明，但是浏览器都支持*/
    word-break: break-word;
    word-wrap: break-word;
    white-space: normal;
}
.el-menu--inline .el-menu-item {
    height: auto;
    line-height: 1.3;
    padding: 0.2rem 0.3rem;
    padding-left: 30px !important;
}
</style>
