import Public from "./public/index"  //用户模块翻译文件引入

const CN = {
    Language: {
        type: '中文'
    },
    Public: Public.CN,
}
const EN = {
    Language: {
        type: 'English'
    },
    Public: Public.EN,
}
export default {
    CN,
    EN
}