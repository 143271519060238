const info = {
    CN: {
        //中文翻译
        banner: {
            title: "营运车队风险管控服务",
        },
        control: {
            title: "车队风险管控服务",
            list: [
                {
                    title: "风险评估算法模型",
                    text: "定制化的风险评估算法模型，精准识别车辆驾驶安全风险",
                },
                {
                    title: "异常预警",
                    text: "对超速驾驶、疲劳驾驶以及其他驾驶行为不好、风险较高的车辆进行预警及持续关注",
                },
                {
                    title: "风险干预",
                    text: "对存在驾驶安全风险的车辆进行风险评级，进行有效风控干预",
                },
                {
                    title: "风控服务",
                    text: "对风险事故案件进行有效的事故现场还原，识别欺诈，减损降赔",
                },
                {
                    title: "分析报告",
                    text: "个体车辆数字安全画像、车队风险运营风险评估报告",
                },
            ],
        },
    },
    EN: {
        //英文翻译
        banner: {
            title: "Fleet Risk Control",
        },
        control: {
            title: "",
            list: [
                {
                    title: "Risk Assessment Model",
                    text: "Customized risk assessment algorithm models for accurately identifying vehicle driving safety risks.",
                },
                {
                    title: "Anomaly Warning",
                    text: "We give warnings to drivers regarding speeding, fatigue driving, and other unsafe behaviors with high risks, and keep monitoring subsequent performance.",
                },
                {
                    title: "Risk Intervention",
                    text: "We provide individual solutions to different levels of risk.",
                },
                {
                    title: "Risk Control",
                    text: "We help identify the rationality of the claim and reduce loss by assessing accident cases and identifying fraud.",
                },
                {
                    title: "Analysis Report",
                    text: "We provide customized solutions to each vehicle according to its safety assessment; guide the fleet to avoid risks by helping them optimize their operations.",
                },
            ],
        },
    },
};
export default info; //需要暴露出去
