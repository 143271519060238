const info = {
    CN: {
        //中文翻译
        services: {
            title: "我们的服务",
            text: "基于人工智能与保险公司、大出行行业共创定制化、智能化、创新化的科技服务，“物联网大数据+深度学习算法”赋能保险新生命，“保险+创新”赋能汽车生态圈",
        },
        scopeOfService: {
            title: "服务类型",
            list: [
                {
                    title: "UBI车联网服务",
                    text: "基于驾驶行为进行数据风险分析，为保险公司提供个性化创新车险产品风控定价算法模型；为主机厂、物流、营运车队等企业提供车辆运行状态监控、驾驶行为风控预警、降低出险概率、提高运营效率的一体化解决方案",
                    look: "查看详情",
                },
                {
                    title: "新能源汽车解决方案",
                    text: "基于车联网大数据、智能算法系统，为新能源主机厂提供创新定制化智能充换电策略、车险运营方案，为新能源车主出行提供安全保障",
                    look: "查看详情",
                },
                {
                    title: "营运车队风险管控服务",
                    text: "基于定制化的风险评估算法模型，对驾驶行为进行评分，帮助保险公司对承保车辆进行风险把控，助力出行企业管理车队，帮助司机改善驾驶行为",
                    look: "查看详情",
                },
                {
                    title: "物流/配送行业安全保障服务",
                    text: "为新兴互联网行业如外卖行业、快递物流等行业提供基于深度神经网络、弱监督学习的场景化风险管控解决方案",
                    look: "查看详情",
                },
            ],
        },
    },
    EN: {
        //英文翻译
        services: {
            title: "Services",
            text: "Based on AI and Deep Learning Perception Algorithms, guiding insurance and Automobile Industry Chain move into a new stage.",
        },
        scopeOfService: {
            title: "Scope Of Service",
            list: [
                {
                    title: "User Behavior Insurance Services with Telematics",
                    text: "We provide insurance companies with the customized risk management pricing model, which is based on the analysis of the user behavior. For engine plants and other enterp rises, our product is the integrated solution aiming at risk control and early warning, reducing accident probability and improving efficiency.",
                    look: "LEARN MORE HERE",
                },
                {
                    title: "New Energy Vehicle Solutions",
                    text: "Based on the big data and intelligent algorithm system of the Internet of vehicles, we customize charging and replacement strategy and auto insurance scheme for new energy host plants and guarantee the safety of new energy owners.",
                    look: "LEARN MORE HERE",
                },
                {
                    title: "Fleet Risk Control",
                    text: "Score driving behaviors based on the customized risk assessment algorithm model to assist insurance companies control risks of insured vehicles, help transit companies manage fleet and guide drivers to drive safely.",
                    look: "LEARN MORE HERE",
                },
                {
                    title: "Emerging Industry Security",
                    text: "Provide scenario risk control solutions based on deep neural network and weak supervised learning for emerging Internet industries, such as takeout and express logistics.",
                    look: "LEARN MORE HERE",
                },
            ],
        },
    },
};
export default info; //需要暴露出去
