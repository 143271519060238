import Vue from 'vue'
import LanguageData from './components/language/index'
import VueI18n from 'vue-i18n'
import router from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import Vant from 'vant';
import 'vant/lib/index.css';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
Vue.config.productionTip = false
// var baseURL="https://official.sigreal.com.cn/api"
var baseURL=window.location.protocol+'//'+window.location.host+"/api"
Vue.prototype.$axios=axios;
Vue.prototype.instance=Vue.prototype.$axios.create({
		  baseURL
})
Vue.use(ElementUI);
Vue.use(Vant);
Vue.use(VueI18n);
const i18n=new VueI18n({
    // locale:localStorage.getItem('languageSet')||'CN',   //从localStorage里获取用户中英文选择，没有则默认中文
    locale:getLang(),   
	messages:{
		'CN': LanguageData.CN,
		'EN': LanguageData.EN
	}
})

new Vue({
  router,i18n,
  render: h => h(App)
}).$mount('#app')
router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  // document.documentElement.scrollTop = 0
  next()
})
// 获取 URL 参数
Vue.prototype.getQueryString=function(name) {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
    var href = window.location.href;
    href=decodeURI(href);
    var r = href.substr(href.indexOf("?") + 1).match(reg);
    if (r != null) return unescape(r[2]);
    return null;
}
//获取浏览器语言
function getLang(){
  let type=navigator.appName;
  let lang='';
  if (type=="Netscape") {
      lang = navigator.language 
  }else{
      lang = navigator.userLanguage 
  }
  if(lang=='zh-CN'){
      return 'CN' 
  }else{
      return 'EN'
  }
}