import Header from "./header"
import Footer from './footer'
import Home from './home'
import Service from './service'
import UBIService from './UBIService'
import NEVService from './NEVService'
import ControlService from './controlService'
import SecurityService from './securityService'
import CompanyDynamic from './companyDynamic'
import JoinUs from './JoinUs'
import PostDetail from './postDetail'
const CN={
    Header: Header.CN,
    Footer: Footer.CN,
    Home: Home.CN,
    Service: Service.CN,
    UBIService: UBIService.CN,
    NEVService: NEVService.CN,
    ControlService: ControlService.CN,
    SecurityService: SecurityService.CN,
    CompanyDynamic: CompanyDynamic.CN,
    JoinUs: JoinUs.CN,
    PostDetail: PostDetail.CN,
}
const EN={
    Header: Header.EN,
    Footer: Footer.EN,
    Home: Home.EN,
    Service: Service.EN,
    UBIService: UBIService.EN,
    NEVService: NEVService.EN,
    ControlService: ControlService.EN,
    SecurityService: SecurityService.EN,
    CompanyDynamic: CompanyDynamic.EN,
    JoinUs: JoinUs.EN,
    PostDetail: PostDetail.EN,
}
export default{
    CN,
    EN
}
