const info = {
    CN: {
        //中文翻译
        banner: {
            title: "物流/配送行业安全保障服务",
        },
        security: {
            title: "物流/配送行业安全保障服务",
            text: "基于强化学习、图卷积神经网络等技术，为个体行为风险识别量身定制深度学习模型，形成“一人一像”的驾驶行为画像，精准预测配送员驾驶风险，对配送员风险行为进行快速干预。帮助物流、配送企业改善配送员管理体系，打造配送员配送行为的正向提升机制。风险事件发生后，通过深度学习等AI技术监控欺诈风险，锁定欺诈事件，为降赔减损提供技术支持。",
        },
    },
    EN: {
        //英文翻译
        banner: {
            title: "Logistics/Delivery Industry Safety and Security Services",
        },
        security: {
            title: "Security Service",
            text: 'Leveraging technologies such as reinforcement learning and graph convolutional neural networks, this approach tailors deep learning models specifically for individual behavior risk identification, creating a "one model per person" driving behavior profile. This enables the precise prediction of delivery personnel driving risks and allows for rapid intervention in risky behaviors. It assists logistics and delivery companies in improving their management systems for delivery personnel, fostering a positive enhancement mechanism for delivery behaviors. In the event of a risk occurrence, AI technologies like deep learning are employed to monitor fraud risks, pinpoint fraudulent activities, and provide technical support for reducing claims and mitigating losses.',
        },
    },
};
export default info; //需要暴露出去
